import {Injectable, OnDestroy} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {GetBusinessParties} from './business-parties.action';
import {BusinessPartiesStateModel, ExtendedBusinessPartyModel} from './business-parties.state';
import {GetNotEMDIS} from './business-parties-not-emdis.actions';
import orderBy from 'lodash-es/orderBy';
import {getEntitiesByFilter} from '@matchsource/store/core';
import {BusinessPartiesApiService} from '@matchsource/api/business-parties';
import {
  BusinessPartyExtendedModel,
  BusinessPartyModel,
  BusinessPartyTypes,
  TCS_WITH_INTERNATIONAL_BEHAVIOUR,
} from '@matchsource/models/business-party';
import {BusinessPartiesNotEMDISState, BusinessPartiesNotEMDISStateModel} from './business-parties-not-emdis.state';
import {CuratedSearchStateModel} from './curated-search.state';
import {GetCuratedSearchIds} from './curated-search.actions';

const byTypeFilter = (type: BusinessPartyTypes) => (entity: BusinessPartyModel) => entity.type === type;

const tcById = (state: BusinessPartiesStateModel) => (id: MsApp.Guid) => state.entities[id];

@Injectable({
  providedIn: 'root',
})
export class BusinessPartiesService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly data$: Observable<BusinessPartiesStateModel>;

  public readonly tcList$: Observable<BusinessPartyModel[]>;

  public readonly cpList$: Observable<BusinessPartyModel[]>;

  public readonly notEMDIStcList$: Observable<BusinessPartyModel[]>;

  public readonly loading$: Observable<boolean>;

  public readonly bpNotEMDISData$: Observable<BusinessPartiesNotEMDISStateModel>;

  public readonly tc500Info$: Observable<BusinessPartyModel>;

  public readonly curatedSearchData$: Observable<CuratedSearchStateModel>;

  public readonly curatedSearchProgramList$: Observable<BusinessPartyModel[]>;

  @Select(BusinessPartiesNotEMDISState.list)
  idsNotEMDIS$: Observable<string[]>;

  constructor(
    private readonly store: Store,
    private readonly businessPartiesApiService: BusinessPartiesApiService
  ) {
    this.data$ = this.store
      .select((state: any) => state.businessParties as BusinessPartiesStateModel)
      .pipe(
        distinctUntilChanged(),
        tap(bpState => {
          if (!bpState.loaded && !bpState.loading) {
            this.store.dispatch(new GetBusinessParties());
          }
        }),
        filter(bpState => bpState.loaded),
        takeUntil(this.destroy$),
        shareReplay({refCount: true, bufferSize: 1})
      );

    this.bpNotEMDISData$ = this.store
      .select((state: any) => state.businessPartiesNotEMDIS as BusinessPartiesNotEMDISStateModel)
      .pipe(
        distinctUntilChanged(),
        tap(bpNotEMDISState => {
          if (!bpNotEMDISState.loaded && !bpNotEMDISState.loading) {
            this.store.dispatch(new GetNotEMDIS());
          }
        }),
        takeUntil(this.destroy$),
        shareReplay({refCount: true, bufferSize: 1})
      );

    this.tcList$ = this.data$.pipe(
      map((state: BusinessPartiesStateModel) =>
        orderBy(
          getEntitiesByFilter<BusinessPartyModel>(state, byTypeFilter(BusinessPartyTypes.TransplantCenter)),
          'identifier',
          'asc'
        )
      )
    );

    this.cpList$ = this.data$.pipe(
      map((state: BusinessPartiesStateModel) =>
        orderBy(
          getEntitiesByFilter<BusinessPartyModel>(state, byTypeFilter(BusinessPartyTypes.CommunityPractice)),
          'identifier',
          'asc'
        )
      )
    );

    this.notEMDIStcList$ = combineLatest([this.tcList$, this.idsNotEMDIS$]).pipe(
      map(([tcList, notEMDISids]) => tcList.filter(el => notEMDISids.includes(el.id)))
    );

    this.loading$ = combineLatest([this.data$, this.bpNotEMDISData$, this.curatedSearchData$]).pipe(
      map(
        ([bpState, bpNotEMDISState, curatedSearchState]) =>
          bpState.loading && bpNotEMDISState.loading && curatedSearchState.loading
      )
    );

    this.tc500Info$ = this.tcList$.pipe(
      map(list => {
        const index = list.findIndex(tc => tc.identifier === TCS_WITH_INTERNATIONAL_BEHAVIOUR.HLA_TODAY_TC);
        return list[index];
      })
    );

    this.curatedSearchData$ = this.store
      .select((state: any) => state.curatedSearch as CuratedSearchStateModel)
      .pipe(
        distinctUntilChanged(),
        tap(curatedSearchState => {
          if (!curatedSearchState.loaded && !curatedSearchState.loading) {
            this.store.dispatch(new GetCuratedSearchIds());
          }
        }),
        takeUntil(this.destroy$),
        shareReplay({refCount: true, bufferSize: 1})
      );

    this.curatedSearchProgramList$ = combineLatest([this.curatedSearchData$, this.tcList$]).pipe(
      filter(([curatedSearch]) => !!curatedSearch),
      map(([curatedSearch, tcList]) => tcList.filter(el => curatedSearch.ids.includes(el.id)))
    );
  }

  getTcById(id: MsApp.Guid): Observable<ExtendedBusinessPartyModel> {
    return combineLatest([of(id), this.data$]).pipe(
      filter(([_tcId, data]) => data.loaded || !!data.error),
      map(([tcId, data]) => tcById(data)(tcId))
    );
  }

  getExtendedTc(id: MsApp.Guid): Observable<BusinessPartyExtendedModel> {
    return this.businessPartiesApiService.getBPExtended(id);
  }

  isPatientRestricted(id: MsApp.Guid): Observable<boolean> {
    return this.getTcById(id).pipe(map(bp => bp?.isPatientRestricted ?? false));
  }

  isEligibleForEps(id: MsApp.Guid): Observable<boolean> {
    return this.getTcById(id).pipe(map(bp => bp?.eligibleForEps ?? false));
  }

  isEligibleForGamida(id: MsApp.Guid): Observable<boolean> {
    return this.getTcById(id).pipe(map(bp => bp?.eligibleForGamida ?? false));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
