import {BusinessPartiesApiService} from '@matchsource/api/business-parties';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap, map, finalize} from 'rxjs/operators';
import {GetNotEMDIS} from './business-parties-not-emdis.actions';

export interface BusinessPartiesNotEMDISStateModel {
  loading: boolean;
  loaded: boolean;
  idsNotEMDIS: string[];
}

const initState = (): BusinessPartiesNotEMDISStateModel => ({
  loading: false,
  loaded: false,
  idsNotEMDIS: [],
});

@State<BusinessPartiesNotEMDISStateModel>({
  name: 'businessPartiesNotEMDIS',
  defaults: initState(),
})
@Injectable()
export class BusinessPartiesNotEMDISState {
  constructor(private readonly businessPartyApi: BusinessPartiesApiService) {}

  @Selector([BusinessPartiesNotEMDISState])
  static list(state: BusinessPartiesNotEMDISStateModel) {
    return state.idsNotEMDIS;
  }

  @Action(GetNotEMDIS)
  getNotEMDIS(ctx: StateContext<BusinessPartiesNotEMDISStateModel>) {
    ctx.patchState({loading: true});

    return this.businessPartyApi.getBPsNotEMDIS().pipe(
      map(bps => bps.map(el => `${el.id}`)),
      tap(idsNotEMDIS =>
        ctx.patchState({
          idsNotEMDIS,
          loaded: true,
        })
      ),
      finalize(() => ctx.patchState({loading: false}))
    );
  }
}
