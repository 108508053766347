import {SearchStatus} from '@matchsource/models/search';

export enum CoopSourceTypes {
  Donor = 'donor',
  Cord = 'cord',
  Both = 'both',
  None = 'none',
  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-duplicate-enum-values
  ' ' = 'none',
}

export enum AutoSaveFormTypeCode {
  DonorWorkup = 'DNRWKUP',
  CordShipment = 'CORDSHP',
  PatientEntry = 'PATENTRY',
  PatientCpEntry = 'PATCPENTRY',
  NewDonorWorkup = 'NEWWKUP',
  SubsequentDonorWorkup = 'SECWKUP',
}

export const enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface FieldSortParams {
  sortField: string;
  sortDir: SortDirection;
}

export interface PagingRequestParams {
  page?: number;
  size?: number;
  sort?: FieldSortParams;
}

export const PATIENT_RESTRICTED_VALUES = ['PATRESTRICT', 'DNRPATRESTRICT', 'CRDPATRESTRICT', 'ALLRESTRICT'];
export const DONOR_RESTRICTED_VALUES = ['DNRPATRESTRICT', 'ALLRESTRICT'];
export const CORD_RESTRICTED_VALUES = ['CRDPATRESTRICT', 'ALLRESTRICT'];
export const GENDER_SELF_IDENTIFIED = 'SI';
export const RHD = [
  {
    id: 'Rh+',
    description: '+',
  },
  {
    id: 'Rh-',
    description: '-',
  },
];

export enum TransplantTimeline {
  LessThen4W = '4W',
  Between4N6W = '4-6W',
  Between7N12W = '7-12W',
  Between12WN6M = '12W-6M',
  GreaterThen6M = '6MG',
  NotAvailable = 'NA',
  Pending = 'PEND',
}

export const TRANSPLANT_TIMELINE_LABEL = {
  [TransplantTimeline.LessThen4W]: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.LESS_THAN_4W',
  [TransplantTimeline.Between4N6W]: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.BETWEEN_4_6_W',
  [TransplantTimeline.Between7N12W]: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.BETWEEN_7_12_W',
  [TransplantTimeline.Between12WN6M]: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.OVER_12W_UP_TO_6M',
  [TransplantTimeline.GreaterThen6M]: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.GREATER_THAN_6M',
  [TransplantTimeline.NotAvailable]: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.NA',
  [TransplantTimeline.Pending]: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.PENDING',
  NO_HISTORY: 'PATIENT.TRANSPLANT_TIMELINE_PERIOD.NO_HISTORY_DATA',
};
export const SEARCH_STATUS_LABEL = {
  [SearchStatus.Pending]: 'SEARCH_STATUS.CODES.PENDING',
  [SearchStatus.InQueue]: 'SEARCH_STATUS.CODES.INQUEUE',
  [SearchStatus.Running]: 'SEARCH_STATUS.CODES.RUNNING',
  [SearchStatus.Completed]: 'SEARCH_STATUS.CODES.COMPLETED',
  [SearchStatus.Failed]: 'SEARCH_STATUS.CODES.FAILED',
  [SearchStatus.Deferred]: 'SEARCH_STATUS.CODES.DEFERRED',
  [SearchStatus.Halted]: 'SEARCH_STATUS.CODES.HALTED',
};
