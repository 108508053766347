import {BusinessPartyExtended} from '@matchsource/api-generated/common';
import {BusinessPartyExtendedFieldsModel, PRIMARY_ADDRESS} from '@matchsource/models/business-party';
import {isEmptyString} from '@matchsource/utils';
import {Serializer} from '@matchsource/api-utils';

export const bpExtendedSerializer: Serializer<BusinessPartyExtendedFieldsModel, BusinessPartyExtended> = {
  fromDTO(input: BusinessPartyExtended): BusinessPartyExtendedFieldsModel {
    const primaryAddress = input.addresses.find(address => address.typeCode === PRIMARY_ADDRESS);
    return {
      address1: primaryAddress.address1,
      address2: primaryAddress.address2,
      city: primaryAddress.city,
      state: isEmptyString(primaryAddress.state) ? null : primaryAddress.state, // state can be consisting of only spaces
      zipCode: primaryAddress.zipCode,
      id: input.bpGuid,
      name: input.doingBusinessAsName,
      countryCode: input.countryCode,
    };
  },
};
