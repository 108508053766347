import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BusinessPartySerializerService} from './business-party-serializer.service';
import {map} from 'rxjs/operators';
import {BusinessPartyControllerService} from '@matchsource/api-generated/common';
import {
  BusinessPartyContractStatus,
  BusinessPartyExtendedModel,
  BusinessPartyModel,
} from '@matchsource/models/business-party';
import {bpExtendedSerializer} from './business-party-extended.serializer.service';
import {HttpContext} from '@angular/common/http';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

enum SuppliedSource {
  Both = 'BOTH',

  Cord = 'CORD',

  Donor = 'DONOR',
}

enum SearchMethod {
  Electronic = 'ELECTRONIC',

  EMDIS = 'EMDIS',

  Manual = 'MANUAL',

  NotEMDIS = 'NOT_EMDIS',

  Unknown = 'UNKNOWN',
}

@Injectable({
  providedIn: 'root',
})
export class BusinessPartiesApiService {
  constructor(
    private readonly businessPartyControllerService: BusinessPartyControllerService,
    private readonly serializer: BusinessPartySerializerService
  ) {}

  private static getBasicCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadEssentialAppData);
  }

  getBPs(): Observable<BusinessPartyModel[]> {
    const context = BusinessPartiesApiService.getBasicCustomErrorHandling();
    const requestOptions = {context: context()};

    return this.businessPartyControllerService
      .byBpType(
        {
          types: ['CBB', 'REG', 'DC', 'BMD', 'BMC', 'TC', 'CCS', 'CDS', 'CP', 'BB'],
        },
        requestOptions
      )
      .pipe(map(bps => bps.map(bp => this.serializer.fromDTO(bp))));
  }

  getCoopRegistries(contractStatuses: BusinessPartyContractStatus[]): Observable<BusinessPartyModel[]> {
    return this.businessPartyControllerService
      .byBpType({
        contractStatuses,
        suppliedSources: [SuppliedSource.Both, SuppliedSource.Cord, SuppliedSource.Donor],
        searchMethods: [SearchMethod.Electronic, SearchMethod.Manual],
      })
      .pipe(map(bps => bps.map(bp => this.serializer.fromDTO(bp))));
  }

  getBPsNotEMDIS(): Observable<BusinessPartyModel[]> {
    return this.businessPartyControllerService
      .byBpType({
        types: ['TC'],
        searchMethods: ['MANUAL', 'ELECTRONIC'],
      })
      .pipe(map(bps => bps.map(bp => this.serializer.fromDTO(bp))));
  }

  getBPExtended(bpGuid: MsApp.Guid): Observable<BusinessPartyExtendedModel> {
    return this.businessPartyControllerService.byBpGuidExtended({bpGuid}).pipe(
      map(bp => ({
        ...this.serializer.fromDTO(bp),
        ...bpExtendedSerializer.fromDTO(bp),
      }))
    );
  }
}
