import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {bindToInstance, isBoolean} from '@matchsource/utils';
import {isUsCountry} from '@matchsource/models/country';
import {BusinessParty} from '@matchsource/api-generated/common';
import {BusinessPartyTypes, BusinessPartyModel} from '@matchsource/models/business-party';
import capitalize from 'lodash-es/capitalize';

import {
  CoopSourceTypes,
  CORD_RESTRICTED_VALUES,
  DONOR_RESTRICTED_VALUES,
  PATIENT_RESTRICTED_VALUES,
} from '@matchsource/models/shared';

const bpName = (data: BusinessParty) => data.doingBusinessAsName || data.bpName;
const formatName = (data: BusinessParty) => {
  const name = bpName(data);
  return name ? `${data.identifier} - ${name}` : data.identifier;
};
const formatNameCp = (data: BusinessParty) => {
  const name = bpName(data);
  return name ? `${name} - ${data.identifier}` : data.identifier;
};
const isDomestic = (data: BusinessParty) => !data.internationalBehavior && isUsCountry(data.countryCode);
const isActive = (data: BusinessParty) => data.status === 'A';
const allowCOOPs = (data: BusinessParty) =>
  isBoolean(data.showCoopSearch) ? data.showCoopSearch : isUsCountry(data.countryCode);

@Injectable({
  providedIn: 'root',
})
export class BusinessPartySerializerService implements Serializer<BusinessPartyModel, BusinessParty> {
  fromDTO(input: BusinessParty): BusinessPartyModel {
    return bindToInstance(new BusinessPartyModel(), {
      id: input.bpGuid,
      bpName: input.bpName,
      doingBusinessAsName: input.doingBusinessAsName,
      name: bpName(input),
      formattedName: formatName(input),
      formattedNameCp: formatNameCp(input),
      type: input.bpTypeCode as BusinessPartyTypes,
      countryCode: input.countryCode,
      identifier: input.identifier,
      isDomestic: isDomestic(input),
      isActive: isActive(input),
      identQualifier: input.identQualifier,
      sourceType: CoopSourceTypes[capitalize(input.suppliedSource)],
      ion: input.ion,
      allowCOOPs: allowCOOPs(input),
      restrictionType: input.restrictionType,
      isPatientRestricted: PATIENT_RESTRICTED_VALUES.includes(input.restrictionType),
      isCordRestricted: CORD_RESTRICTED_VALUES.includes(input.restrictionType),
      isDonorRestricted: DONOR_RESTRICTED_VALUES.includes(input.restrictionType),
      patientProcess: input.patientProcess,
      domestic: input.domestic,
      internationalBehavior: input.internationalBehavior,
      internationalFormRequired: input.internationalFormRequired,
      subsequentInternationalFormApplicable: input.subsequentInternationalFormApplicable,
      showAnalyticsMenu: input.showAnalyticsMenu,
    });
  }
}
