import {BusinessPartyModel} from '@matchsource/models/business-party';

export class AddOrReplaceBusinessParties {
  static readonly type = '[BusinessParties] Add or replace';

  constructor(public bps: BusinessPartyModel[]) {}
}

export class GetBusinessParties {
  static readonly type = '[BusinessParties] Get Business Parties';
}
