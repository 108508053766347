import {EntitiesStateModel, EntityStateModel} from './model';

export type ExtractEntityModel<StateModel> = StateModel extends EntitiesStateModel<infer Model> ? Model : never;

export function selectEntities<T>(state: EntitiesStateModel<T>): T[] {
  return state.ids.map(id => state.entities[id]);
}

export function getEntitiesByFilter<T>(state: EntitiesStateModel<T>, filter: (item: T) => boolean): T[] {
  return state.ids.filter(id => filter(state.entities[id])).map(id => state.entities[id]);
}

export function defaultEntitiesState<T>(defaults: Partial<EntitiesStateModel<T>> = {}): EntitiesStateModel<T> {
  return {
    loaded: false,
    loading: false,
    entities: {},
    ids: [],
    error: undefined,
    ...defaults,
  };
}

export function defaultEntityState<T>(defaults: Partial<EntityStateModel<T>> = {}): EntityStateModel<T> {
  return {
    loaded: false,
    loading: false,
    entity: undefined,
    error: undefined,
    ...defaults,
  };
}
